@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Joystix';
  src: local('Joystix'), url('./fonts/joystix.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Joystix','comic sans ms';
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
  background-color: #272531;
  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a:link {
 
  color: #479ff8;
}
a:visited {
 
  color: #CC99CC;
}

button:hover {
  /* enlarge 1.05 */
  transform: scale(1.05);
  transition: transform 0.1s;
}